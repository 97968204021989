import {
  AchievementIcon,
  AnswerCorrectIcon,
  BriefcaseIcon,
  CashIcon,
  CertificateIcon,
  CheckIcon,
  ClockOutlineIcon,
  ClosedCaptioningIcon,
  CorrectAnswerIcon,
  CurriculumIcon,
  DegreeIcon,
  FolderIcon,
  GlobeOneIcon,
  GradingIcon,
  HelpIcon,
  InstitutionIcon,
  InstructorIcon,
  MembersIcon,
  OpenBookIcon,
  ReplyIcon,
  TargetIcon,
  TrophyIcon,
  VersionHistoryIcon,
} from '@coursera/cds-icons';

// The possible input values are controlled by a select field in Contentful and is controlled/maintained by engineering
export const validIcons = {
  AchievementIcon,
  BriefcaseIcon,
  CashIcon,
  CertificateIcon,
  CheckIcon,
  ClockOutlineIcon,
  ClosedCaptioningIcon,
  ReplyIcon,
  CorrectAnswerIcon,
  CurriculumIcon,
  FolderIcon,
  GlobeOneIcon,
  GradingIcon,
  DegreeIcon,
  HelpIcon,
  InstitutionIcon,
  InstructorIcon,
  MembersIcon,
  OpenBookIcon,
  VersionHistoryIcon,
  AnswerCorrectIcon,
  TargetIcon,
  TrophyIcon,
};
