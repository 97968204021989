/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';

import { FormattedHTMLMessage } from 'js/lib/coursera.react-intl';

import { Button, Dialog, InlineNotification, Typography2, breakpoints } from '@coursera/cds-core';
import { DegreeIcon, ExternalLinkIcon } from '@coursera/cds-icons';

import { getQualifyingQuestionsExpt } from 'bundles/degree-description/utils/experiments';
import { useEoiOptimizationsContext } from 'bundles/expression-of-interest/components/EoiOptimizationsContext';
import { getRequiredEducation } from 'bundles/expression-of-interest/components/experiments/qualifyingQuestionsUtils';
import { useEoiEventing } from 'bundles/expression-of-interest/utils/eventingUtils';

import _t from 'i18n!nls/expression-of-interest';

type Props = {
  isQualified?: boolean;
  isStandaloneQualifyingQuestions: boolean;
  applicationUrl?: string | null;
  degreeDeadline?: string;
  hasSkippedQQForm?: boolean;
  degreeProductVariant?: string;
  trackingProductId: string;
};

const styles = {
  capIcon: css`
    margin-bottom: var(--cds-spacing-200);
    height: 64px;
    width: 64px;
  `,
  buttonGroup: css`
    display: flex;
    gap: var(--cds-spacing-200);

    ${breakpoints.down('xs')} {
      flex-direction: column;
    }

    a {
      padding: 12px 24px;
    }
  `,
  infoGroupStart: css`
    margin-bottom: var(--cds-spacing-200);
  `,
  infoGroupEnd: css`
    margin-top: var(--cds-spacing-200);
  `,
  orderedList: css`
    padding-left: var(--cds-spacing-200);
  `,
  paragraphSpacing: css`
    margin-bottom: var(--cds-spacing-300);
  `,
};

const NextStepsSection = ({
  isQualified,
  isStandaloneQualifyingQuestions,
  applicationUrl,
  degreeDeadline,
  hasSkippedQQForm,
  degreeProductVariant,
  trackingProductId,
}: Props) => {
  const { slug } = useEoiOptimizationsContext();
  const qualifyingQuestionsVariant = getQualifyingQuestionsExpt(slug, true);
  const { trackClickButton, trackInteractEoi } = useEoiEventing(trackingProductId);
  const { HeadingGroup, Content, Actions } = Dialog;

  const educationRequirement = getRequiredEducation(degreeProductVariant);
  const applicationStatsText = () =>
    // eslint-disable-next-line no-restricted-syntax
    _t('Join the 3,000 learners who started their degree applications on Coursera this week.');

  return (
    <div className="rc-NextStepsSection">
      <HeadingGroup>{_t('Next steps')}</HeadingGroup>
      <Content>
        {(qualifyingQuestionsVariant === 'A' || hasSkippedQQForm) && (
          <>
            <DegreeIcon css={styles.capIcon} size="large" />
            {!isStandaloneQualifyingQuestions && (
              <Typography2 css={styles.paragraphSpacing} component="p" variant="bodyPrimary">
                {_t(
                  'Keep an eye out in your inbox for an email that includes more information about this specific program.'
                )}
              </Typography2>
            )}
            <Typography2 component="p" variant="bodyPrimary">
              {_t('Here are some next steps you can take:')}
              <ol css={styles.orderedList}>
                {degreeDeadline && (
                  <li>
                    <FormattedHTMLMessage
                      message={_t(
                        'Continue the momentum and head to the university website to start the application process. The application deadline is <strong>{applicationDeadline}</strong>.'
                      )}
                      applicationDeadline={degreeDeadline}
                    />
                  </li>
                )}
                <li>{_t('Learn more about the application requirements on the program page.')}</li>
              </ol>
            </Typography2>
            <InlineNotification>{applicationStatsText()}</InlineNotification>
          </>
        )}
        {qualifyingQuestionsVariant === 'B' && !hasSkippedQQForm && (
          <>
            {isQualified ? (
              <>
                <InlineNotification css={styles.infoGroupStart} severity="success">
                  {_t('Congrats! You meet the education requirement for this degree.')}
                </InlineNotification>
                <Typography2 css={styles.paragraphSpacing} component="p" variant="bodyPrimary">
                  {!isStandaloneQualifyingQuestions && (
                    <>
                      {_t(
                        'Keep an eye out in your inbox for an email that includes more information about this specific program.'
                      )}
                      {/* Need this non-breaking space to have a gap between this block of text and the next bit in the same paragraph */}
                      &nbsp;
                    </>
                  )}
                  {_t(
                    "We recommend that you consider starting your application, which is handled on the university's website."
                  )}
                </Typography2>
                {degreeDeadline && (
                  <Typography2 component="p" variant="bodyPrimary">
                    <FormattedHTMLMessage
                      message={_t('The application deadline is <strong>{applicationDeadline}</strong>.')}
                      applicationDeadline={degreeDeadline}
                    />
                  </Typography2>
                )}
                <InlineNotification css={styles.infoGroupEnd}>{applicationStatsText()}</InlineNotification>
              </>
            ) : (
              <>
                <DegreeIcon css={styles.capIcon} size="large" />
                {educationRequirement && (
                  <InlineNotification css={styles.infoGroupStart}>
                    {_t('This program requires a #{educationRequirement}.', {
                      educationRequirement,
                    })}
                  </InlineNotification>
                )}
                {!isStandaloneQualifyingQuestions && (
                  <Typography2 css={styles.paragraphSpacing} component="p" variant="bodyPrimary">
                    {_t(
                      'Keep an eye out in your inbox for an email that includes more information about this specific program.'
                    )}
                  </Typography2>
                )}
                <Typography2 component="p" variant="bodyPrimary">
                  {_t('Here are some next steps you can take:')}
                  <ol css={styles.orderedList}>
                    <li>{_t('Learn more about the application requirements on the program page.')}</li>
                    <li>{_t('Check out more information about the application on the university website.')}</li>
                  </ol>
                </Typography2>
              </>
            )}
          </>
        )}
      </Content>
      <Actions>
        {(qualifyingQuestionsVariant === 'A' || hasSkippedQQForm) && (
          <div
            css={styles.buttonGroup}
            role="group"
            aria-label={_t('Button group with an "Apply now" and a "Learn more about admissions" button')}
          >
            {applicationUrl && (
              <Button
                component="a"
                size="medium"
                variant="primary"
                icon={<ExternalLinkIcon />}
                iconPosition="after"
                href={applicationUrl}
                onClick={() => trackClickButton('degree_apply_from_eoi')}
              >
                {_t('Apply now')}
              </Button>
            )}
            <Button
              size="medium"
              variant="secondary"
              component="a"
              href={`/degrees/${slug}/admissions`}
              onClick={() => trackInteractEoi('learn_more', 'next_steps')}
            >
              {_t('Learn more about admissions')}
            </Button>
          </div>
        )}
        {qualifyingQuestionsVariant === 'B' && !hasSkippedQQForm && (
          <>
            {isQualified ? (
              <>
                {applicationUrl && (
                  <Button
                    component="a"
                    size="medium"
                    variant="primary"
                    icon={<ExternalLinkIcon />}
                    iconPosition="after"
                    href={applicationUrl}
                    onClick={() => trackClickButton('degree_apply_from_eoi')}
                  >
                    {_t('Apply now')}
                  </Button>
                )}
              </>
            ) : (
              <div
                css={styles.buttonGroup}
                role="group"
                aria-label={_t('Button group with an "Learn more about admissions" and a "See application" button')}
              >
                <Button
                  size="medium"
                  variant="primary"
                  component="a"
                  href={`/degrees/${slug}/admissions`}
                  onClick={() => trackInteractEoi('learn_more', 'next_steps')}
                >
                  {_t('Learn more about admissions')}
                </Button>
                {applicationUrl && (
                  <Button
                    component="a"
                    size="medium"
                    variant="secondary"
                    icon={<ExternalLinkIcon />}
                    iconPosition="after"
                    href={applicationUrl}
                    onClick={() => trackClickButton('degree_apply_from_eoi')}
                  >
                    {_t('See application')}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </Actions>
    </div>
  );
};

export default NextStepsSection;
