/** @jsx jsx */
import { jsx } from '@emotion/react';

import * as React from 'react';
import { forwardRef, memo, useCallback, useMemo } from 'react';

import { TextField } from '@coursera/cds-core';

import { AutoCompleteConstants } from 'bundles/survey-form/constants/SurveyFormConstants';

type Props = {
  onChange: (val: { response?: string; text?: string }) => void;
  placeholder: string;
  prompt: string;
  validationError?: string;
  numericType?: boolean;
  defaultValue: string;
  additionalClassName?: string; // for styling purposes
  id: string;
  ariaLabel?: string;
  isRequired?: boolean;
};

const CDSTextInputElement = forwardRef<HTMLInputElement | HTMLTextAreaElement, Props>(
  (
    {
      placeholder,
      defaultValue,
      validationError,
      additionalClassName,
      prompt,
      onChange,
      isRequired = false,
      numericType = false,
      ariaLabel,
      id,
    },
    ref
  ) => {
    const questionAutoComplete = useMemo(() => AutoCompleteConstants[id] ?? 'off', [id]);

    const onChangeWrapper = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
      (e) => {
        const processedResponse: { response?: string; text?: string } = {};
        if (numericType) {
          processedResponse.response = e.target.value;
        } else {
          processedResponse.text = e.target.value;
        }
        onChange(processedResponse);
      },
      [numericType, onChange]
    );

    return (
      <TextField
        id={id}
        fullWidth
        autoComplete={questionAutoComplete}
        type={numericType ? 'number' : 'text'}
        className={additionalClassName}
        defaultValue={defaultValue}
        onChange={onChangeWrapper}
        optional={!isRequired}
        label={prompt}
        placeholder={placeholder}
        validationStatus={validationError ? 'error' : undefined}
        validationLabel={validationError}
        aria-label={ariaLabel}
        aria-describedby={`error-message-${id}`}
        inputRef={ref}
      />
    );
  }
);

export default memo(CDSTextInputElement);
