/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { useRef } from 'react';
import type { MutableRefObject } from 'react';

import Imgix from 'js/components/Imgix';

import { Grid, IconButton, Typography2, breakpoints } from '@coursera/cds-core';
import { useVisibilityTracker } from '@coursera/event-pulse/react';

import Carousel from 'bundles/design-system/components/Carousel';
import type { CarouselSettings } from 'bundles/design-system/components/types';
import type { Maybe } from 'bundles/premium-hub/__generated__/baseContentfulTypes';
import type { TestimonialVisualConsistencyFragment } from 'bundles/premium-hub/__generated__/contentfulTypes';
import Container from 'bundles/premium-hub/components/shared/Container';
import TestimonialIFrameVideo from 'bundles/premium-hub/components/shared/TestimonialIFrameVideo';
import TestimonialVideo from 'bundles/premium-hub/components/shared/TestimonialVideo';
import Quote from 'bundles/premium-hub/components/shared/TestimonialsQuote';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';

import _t from 'i18n!nls/premium-hub';

const testimonialContainerHeight = 434;
const imgMarginVertical = 15;
const imgMarginHorizontal = 25;

const styles = {
  container: () => css`
    background-color: var(--cds-color-blue-950);
  `,
  heading: () => css`
    padding-top: var(--cds-spacing-600);
    font-size: 28px;
    ${breakpoints.down('md')} {
      padding-top: var(--cds-spacing-400);
    }
  `,
  sliderDots: () => css`
    .carousel-slider-dots {
      cursor: pointer;
      position: absolute;
      bottom: var(--cds-spacing-300);
      display: block;
      width: 100%;
      padding: 0;
      list-style: none;
      text-align: center;

      ${breakpoints.down('sm')} {
        bottom: 0;
      }

      li {
        position: relative;
        display: inline-block;
        height: 12px;
        width: 12px;
        margin: 0 6px;
        padding: 0;

        ${breakpoints.down('sm')} {
          height: 48px;
          width: 48px;
          margin: 0;
        }
      }

      button {
        height: 12px;
        width: 12px;
        background-color: var(--cds-color-grey-50);
        border-radius: 10px;
        border: none;

        ${breakpoints.down('sm')} {
          height: 24px;
          width: 24px;
          border-radius: 12px;

          // Increase the clickable area of the button on mobile
          ::before {
            content: '';
            width: 48px;
            height: 48px;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }

      .slick-active {
        button {
          background-color: var(--cds-color-grey-500);
        }
      }
    }
  `,

  carouselWrap: () => css`
    .rc-Carousel .slick-list {
      /* Matches default slick next/prev transition time */
      transition: height 500ms;
    }

    .rc-Carousel.stepped .slick-slider {
      ${breakpoints.up('md')} {
        width: 100%;
      }
    }
  `,
  carouselButtons: () => css`
    .slick-prev,
    .slick-next {
      position: absolute;
      padding: 0;
      cursor: pointer;

      ${breakpoints.up('md')} {
        top: calc(50% - 24px);
      }

      ${breakpoints.down('md')} {
        top: 50%;
      }
    }

    .slick-prev {
      position: absolute;
      left: 24px;
      z-index: 1;
    }

    .slick-next {
      position: absolute;
      right: 24px;
      z-index: 1;
    }

    [dir='rtl'] {
      .slick-prev {
        right: -30px;
        left: auto;
      }

      .slick-next {
        right: auto;
        left: -30px;
      }

      .slick-slide {
        float: right;
      }
    }
  `,
  testimonialCard: () => css`
    background: var(--cds-color-white-0);
    margin: var(--cds-spacing-600);
    margin-bottom: var(--cds-spacing-1000);
    min-width: 0;
    border-radius: var(--cds-spacing-100);
  `,
  testimonialGrid: () => css`
    ${breakpoints.up('md')} {
      height: ${testimonialContainerHeight}px;
    }
    ${breakpoints.down('sm')} {
      min-height: 200px;
    }
  `,
  carouselButtonIconWrap: css`
    display: flex;
  `,
  imageContainer: () => css`
    img {
      margin: ${imgMarginVertical}px auto;
      max-width: calc(100% - ${2 * imgMarginHorizontal}px);
      ${breakpoints.up('sm')} {
        border-radius: 8px;
        max-height: ${testimonialContainerHeight - 2 * imgMarginVertical}px;
      }
      ${breakpoints.down('sm')} {
        max-height: 200px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }
  `,
};

type VideoTestimonialsSectionProps = {
  testimonialsHeader: Maybe<string>;
  testimonials: Maybe<Array<TestimonialVisualConsistencyFragment | null>>;
};

type VideoTestimonialCardProps = {
  index: number;
  testimonial: TestimonialVisualConsistencyFragment;
  setVideoElementRef: (element: HTMLVideoElement | null) => void;
  playVideoElement: () => void;
};

const TestimonialCard = ({ testimonial, index, setVideoElementRef, playVideoElement }: VideoTestimonialCardProps) => {
  const {
    image,
    videoEmbedUrl,
    videoFile,
    audioDescriptionTrack,
    videoPlaceholderImage,
    authorName,
    authorTitle,
    text,
    authorOrganization,
  } = testimonial;
  const testimonialCardRef: React.MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_testimonial_card',
    {
      testimonialCard: {
        author: authorName ?? '',
        text: text ?? '',
        index: index ?? -1,
      },
    }
  );

  const shouldShowVideo = (!!videoPlaceholderImage && !!videoEmbedUrl) || (!!videoPlaceholderImage && !!videoFile);
  const shouldShowImage = !shouldShowVideo;
  const slideNumber = index + 1;

  return (
    <div css={styles.testimonialCard} ref={testimonialCardRef}>
      <Grid container alignItems="center" justifyContent="center" css={styles.testimonialGrid}>
        {shouldShowVideo && (
          <Grid item xs={12} md={6}>
            {videoFile?.url ? (
              <TestimonialVideo
                setVideoElementRef={setVideoElementRef}
                playVideoElement={playVideoElement}
                videoFile={videoFile}
                audioDescriptionTrack={audioDescriptionTrack}
                placeholderImage={videoPlaceholderImage}
                index={index}
                authorName={authorName}
                authorTitle={authorTitle}
                text={text}
                slideNumber={slideNumber}
              />
            ) : (
              <TestimonialIFrameVideo
                videoEmbedUrl={videoEmbedUrl}
                placeholderImage={videoPlaceholderImage}
                index={index}
                authorName={authorName}
                authorTitle={authorTitle}
                text={text}
                slideNumber={slideNumber}
              />
            )}
          </Grid>
        )}
        {shouldShowImage && image?.url && image?.width && image?.height && (
          <Grid item xs={12} md={6} css={styles.imageContainer}>
            <Imgix
              src={image.url}
              alt={
                image?.description ||
                _t('Slide #{slideNumber}. Testimonial by #{authorName}', { authorName, slideNumber })
              }
              loading="lazy"
              imgParams={{ auto: 'format,compress', format: 'avif', fit: 'fill', width: 600, height: 400 }}
            />
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <Quote quoteText={text} authorName={authorName} authorTitle={authorTitle} authorOrg={authorOrganization} />
        </Grid>
      </Grid>
    </div>
  );
};

export const TestimonialsSection = ({ testimonialsHeader, testimonials }: VideoTestimonialsSectionProps) => {
  const videoElementRefs = useRef<Array<HTMLVideoElement | null>>([]);
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const testimonialRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker('view_page_section', {
    pageSection: {
      sectionName: 'learner_testimonials',
      index: sectionIndex,
    },
  });
  const testimonialsToDisplay = testimonials?.filter(Boolean);

  if (!testimonialsToDisplay?.length) return null;

  const onSlideChange = (slide: number) => {
    videoElementRefs.current?.[slide]?.pause();
  };

  const carouselSettings: CarouselSettings = {
    dots: true,
    dotsClass: 'carousel-slider-dots',
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    adaptiveHeight: true,
    useTransform: false,
    prevArrow: (
      <div css={styles.carouselButtonIconWrap} aria-label={_t('previous testimonial')}>
        <IconButton intent="previous" variant="secondary" />
      </div>
    ),
    nextArrow: (
      <div css={styles.carouselButtonIconWrap} aria-label={_t('next testimonial')}>
        <IconButton intent="next" variant="secondary" />
      </div>
    ),
    responsive: [
      {
        breakpoint: breakpoints.values.sm,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
    beforeChange: onSlideChange,
  };

  return (
    <div css={[styles.sliderDots, styles.carouselButtons, styles.carouselWrap, styles.container]} ref={testimonialRef}>
      <Container>
        <Typography2 component="h2" variant="titleSmall" css={styles.heading()} color="invertBody">
          {testimonialsHeader ?? _t('Hear why students enjoy learning on Coursera')}
        </Typography2>
        <Carousel settings={carouselSettings}>
          {testimonialsToDisplay?.map((testimonial, index) => {
            const { authorName, authorTitle, authorOrganization } = testimonial;

            return (
              <div key={`slide-${authorName}-${authorTitle}-${authorOrganization}`}>
                <TestimonialCard
                  testimonial={testimonial}
                  index={index}
                  setVideoElementRef={(element: HTMLVideoElement | null) => {
                    videoElementRefs.current[index] = element;
                  }}
                  playVideoElement={() => {
                    videoElementRefs.current?.[index]?.play();
                  }}
                />
              </div>
            );
          })}
        </Carousel>
      </Container>
    </div>
  );
};

export default React.memo(TestimonialsSection);
