/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import type { SerializedStyles } from '@emotion/react';

import * as React from 'react';

import type { Svg } from '@coursera/coursera-ui/svg';

import type { Props as ButtonProps } from 'bundles/authentication/shared/Button';
import Button from 'bundles/authentication/shared/Button';

type Props = Omit<ButtonProps, 'primary' | 'ghost' | 'secondary'> & {
  icon?: Svg;
  iconColor?: string;
  customStyles?: SerializedStyles;
};

const styles = {
  root: css`
    border: 1px solid #1f1f1f;
    color: #1f1f1f;
    width: 100%;
    position: relative;

    svg {
      left: 24px;
      position: absolute;
      top: 15px;
    }

    &:focus {
      background-color: #f3f8ff;
      color: #1f1f1f;
      text-decoration: none;
    }

    @media (hover: hover) {
      &:hover {
        background-color: #f3f8ff;
      }
    }

    &[disabled] {
      background-color: #bdbdbd;
      color: #fff;

      @media (hover: hover) {
        &:hover {
          background-color: #bdbdbd;
        }
      }
    }
  `,
};

const SocialButton = React.forwardRef(
  ({ children, icon: Icon, iconColor, customStyles, ...props }: Props, ref: React.Ref<HTMLButtonElement>) => (
    <Button {...props} css={[styles.root, customStyles]} ghost ref={ref}>
      {Icon && <Icon aria-hidden="true" color={iconColor} size={18} />}
      {children}
    </Button>
  )
);
SocialButton.displayName = 'SocialButton';

export default SocialButton;
