import { css } from '@emotion/react';

import { classes as baseClasses } from '@core/ButtonBase/getButtonBaseCss';
import generateEmotionClasses from '@core/utils/generateEmotionClasses';

export const classes = generateEmotionClasses('password-field', [
  'root',
  'focused',
  'label',
  'formValidationStatus',
  'formSupportText',
  'validationIcon',
  'valid',
  'invalid',
  'readOnly',
  'disabled',
]);

export const styles = css`
  .${classes.root} {
    margin-top: var(--cds-spacing-100);

    &:only-child {
      margin-top: 0;
    }

    input::placeholder,
    input:hover::placeholder {
      color: var(--cds-color-neutral-primary-weak);
    }
  }

  .${classes.label} {
    margin: 0;
  }

  .${classes.formValidationStatus} {
    margin-top: var(--cds-spacing-100);

    & + .${classes.root} {
      margin-top: var(--cds-spacing-50);
    }
  }

  .${classes.formSupportText} {
    margin: var(--cds-spacing-50) 0 0;
  }

  &.${classes.valid}, &.${classes.invalid} {
    .${classes.validationIcon} {
      padding-left: 0;
      padding-right: var(--cds-spacing-50);
    }
  }

  &.${classes.valid} {
    .${classes.root} {
      &.${classes.focused} {
        .${classes.validationIcon} {
          color: var(--cds-color-feedback-success-hover);
        }
      }

      &:hover {
        background: var(--cds-color-feedback-background-success-weak);

        .${classes.validationIcon} {
          color: var(--cds-color-feedback-success-hover);
        }
      }
    }
  }

  &.${classes.invalid} {
    .${classes.root} {
      &.${classes.focused} {
        .${classes.validationIcon} {
          color: var(--cds-color-feedback-error-hover);
        }
      }

      &:hover {
        background: var(--cds-color-feedback-background-error-weak);

        .${classes.validationIcon} {
          color: var(--cds-color-feedback-error-hover);
        }
      }
    }
  }

  &.${classes.readOnly} {
    .${classes.root} {
      background: var(--cds-color-neutral-background-primary-weak);
    }
  }
`;

export const visibilityToggleCss = css`
  padding-left: 0;
  margin-left: var(--cds-spacing-50);
  padding-right: 6px;
`;

export const visibilityToggleButtonCss = css`
  height: 36px;
  width: 36px;
  box-shadow: inset 0 0 0 1px var(--iconButton-boxShadowColor);
  background-color: var(--iconButton-background);
  color: var(--iconButton-color);
  --iconButton-boxShadowColor: transparent;
  --iconButton-background: unset;
  --iconButton-color: var(--cds-color-neutral-primary);

  &:hover,
  &.${baseClasses.focusVisible} {
    --iconButton-color: var(--cds-color-interactive-primary-hover);
  }

  &.${baseClasses.focusVisible} {
    --iconButton-background: var(
      --cds-color-interactive-background-primary-hover-weak
    );
  }

  &:active {
    --iconButton-background: var(
      --cds-color-interactive-background-primary-pressed-weak
    );
    --iconButton-color: var(--cds-color-interactive-primary-pressed);
  }

  &.${classes.disabled} {
    --iconButton-color: var(--cds-color-neutral-disabled-strong);
  }
`;

export default styles;
