/** @jsx jsx */

/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react';

import * as React from 'react';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { useRetracked } from 'js/lib/retracked';

import { Grid, breakpoints, useMediaQuery } from '@coursera/cds-core';

import usePrevious from 'bundles/common/hooks/usePrevious';
import DegreeCard from 'bundles/premium-hub/components/shared/DegreeCard';
import { UnifiedHubProductVariant } from 'bundles/premium-hub/constants';
import type {
  ProductCardProps as DegreeCardProps,
  DegreeContentfulMetadata,
  PremiumProductWithMetadata,
} from 'bundles/premium-hub/types';
import { getUrlFromProductVariant } from 'bundles/premium-hub/utils';
import { convertPremiumProductWithMetadataToPremiumHubProductCardProps } from 'bundles/premium-hub/utils/dataTransformationUtils';
import { ProductsCardsCdsPlaceholder } from 'bundles/search-common/components/search-cards/Placeholder';
import isPremiumProduct from 'bundles/search-common/components/search-cards/isPremiumProduct';
import type { LEARNING_PRODUCTS } from 'bundles/search-common/constants';
import type { SearchHit, SearchProductHit } from 'bundles/search-common/providers/searchTypes';
import type { SearchIndexHit } from 'bundles/search-common/types';
import { isSearchProductHit, scrollUpIfNecessary } from 'bundles/search-common/utils/utils';

const styles = {
  container: css`
    list-style: none;
    padding-left: 0;
  `,
  /* TrackedLink2's visibility tracking adds an extra div that interferes with the search card styling.
  This wrapper div applies style to that extra div to ensure the search cards are styled correctly. */
  targetSearchCardWrapper: css`
    height: 100%;
    padding: 0 6px 24px;

    ${breakpoints.down('xs')} {
      padding: 0 0 24px 0;
    }

    .cds-CommonCard-previewImage > div {
      padding: 0;
      height: 100%;
    }

    .cds-CommonCard-previewImage .lazyload-wrapper {
      height: 100%;
    }
  `,
  degreeCardRow: css`
    ${breakpoints.down('xs')} {
      border-bottom: 1px solid var(--cds-color-neutral-stroke-primary-weak);
      margin-bottom: var(--cds-spacing-200);
    }
  `,
};

type InputProps = {
  hits?: SearchHit[];
  resultsPerPage: number;
  isSearchPage?: boolean;
  eventingData?: {
    searchIndex: string;
    searchIndexPosition: number;
    page?: string;
    searchQuery?: string;
    filtersApplied?: Record<string, string[]>;
  };
  isLoading?: boolean;
  onScrolledToBottom?: () => void;
  totalHitsDisplayed?: number;
  premiumProductList: PremiumProductWithMetadata[];
  atAGlanceData?: DegreeContentfulMetadata[];
};

type PropsToComponent = InputProps;

export const PREMIUM_PRODUCT_TO_DURATION: Record<string, string | undefined> = {};

const getHitSlug = (objectUrl?: string | null) => {
  if (!objectUrl) {
    return undefined;
  }

  return objectUrl.substring(objectUrl.lastIndexOf('/') + 1);
};

export const combineHitData = (hits: SearchIndexHit[]) =>
  hits.map((hit) => {
    const hitSlug = getHitSlug(hit.objectUrl);

    if (hitSlug && PREMIUM_PRODUCT_TO_DURATION[hitSlug]) {
      // Combine Algolia and Contentful data for premium products
      return {
        ...hit,
        productDuration: PREMIUM_PRODUCT_TO_DURATION[hitSlug],
      };
    }
    return hit;
  });

const convertHitToDegreeCardProduct = (
  hit: SearchProductHit,
  mappedPremiumProducts: Record<string, PremiumProductWithMetadata>,
  atAGlanceData: DegreeContentfulMetadata[] | undefined
): DegreeCardProps => {
  const slug = getHitSlug(hit.url);
  const premiumProductWithMetadata: PremiumProductWithMetadata | undefined =
    slug && slug in mappedPremiumProducts ? mappedPremiumProducts[slug] : undefined;
  const productCardProps: DegreeCardProps | undefined = premiumProductWithMetadata
    ? convertPremiumProductWithMetadataToPremiumHubProductCardProps(premiumProductWithMetadata)
    : undefined;

  const metaData = atAGlanceData?.find((item) => item?.slug === slug) ?? {};
  const convertedPartner = {
    name: hit.partners?.[0],
    partnerLogo: metaData.partnerContentful?.partnerLogo?.url,
    partnerMonotoneLogo: metaData.partnerContentful?.partnerMonotoneLogo?.url,
    country: premiumProductWithMetadata?.partner?.location?.country,
  } as DegreeCardProps['partner'];

  return {
    id: hit.id ?? undefined,
    productVariant: UnifiedHubProductVariant.Degree,
    slug,
    name: hit.name ?? undefined,
    partner: productCardProps?.partner ?? convertedPartner,
    description: productCardProps?.description ?? metaData.atAGlance?.ranking ?? '',
    duration: productCardProps?.duration,
    degreeLevel: productCardProps?.duration,
    bannerImage: productCardProps?.bannerImage,
    upcomingTermDates: productCardProps?.upcomingTermDates,
    timeFrame: productCardProps?.timeFrame ?? metaData.atAGlance?.timeFrame ?? '',
    programDeadlines: productCardProps?.programDeadlines,
    degreeAttributes: {
      hasScholarship: productCardProps?.degreeAttributes?.hasScholarship,
      tuitionInUsd: productCardProps?.degreeAttributes?.tuitionInUsd,
    },
  };
};

const SearchCards = ({
  hits,
  resultsPerPage,
  eventingData,
  isLoading,
  isSearchPage,
  onScrolledToBottom,
  totalHitsDisplayed,
  premiumProductList,
  atAGlanceData,
}: PropsToComponent) => {
  const isTraditionalPagination = !isSearchPage;
  const infiniteScrollTriggerRef = useRef<HTMLDivElement>(null);
  const [hasTriggeredMap, setHasTriggeredMap] = useState<Map<number, boolean>>(new Map());
  const previousHitsCount = usePrevious(totalHitsDisplayed || 0);
  const previousQuery = usePrevious(eventingData?.searchQuery || '');
  const [windowIsSafe, setWindowIsSafe] = useState(false);
  const retrackedTrack = useRetracked();
  const isMobile = useMediaQuery(breakpoints.down('xs'));

  const hasScrolledBottomDetectorIndex = useMemo(() => {
    const targetTriggerIndex = (hits?.length || 0) - 1;
    const triggerShouldBeFound = !hasTriggeredMap.get(targetTriggerIndex) && !!onScrolledToBottom && !isLoading;
    return triggerShouldBeFound ? targetTriggerIndex : -1;
  }, [hits?.length, hasTriggeredMap, onScrolledToBottom, isLoading]);

  const handleScrollBottom = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(
    ([trigger]: IntersectionObserverEntry[]) => {
      const shouldCallback =
        !isLoading && trigger?.isIntersecting && !hasTriggeredMap.get(hasScrolledBottomDetectorIndex);

      if (shouldCallback) {
        setHasTriggeredMap(new Map(hasTriggeredMap.set(hasScrolledBottomDetectorIndex, true)));
        onScrolledToBottom?.();
      }
    },
    [hasScrolledBottomDetectorIndex, hasTriggeredMap, isLoading, onScrolledToBottom]
  );

  const infiniteScrollObserver = useMemo(() => {
    const shouldHaveObserver = onScrolledToBottom && windowIsSafe;

    return shouldHaveObserver
      ? new IntersectionObserver(handleScrollBottom, {
          threshold: [0, 1.0],
        })
      : false;
  }, [handleScrollBottom, onScrolledToBottom, windowIsSafe]);

  useEffect(() => {
    const hasNewQuery = !!previousQuery && previousQuery !== eventingData?.searchQuery;
    // using totalHits as a proxy heuristic for when a new search has executed in order to
    // reset the infinite scroll trigger. should be cleaned up if experiment is successful.
    const displayedHitsCountShrank = (totalHitsDisplayed || 0) < (previousHitsCount || 0);
    if (displayedHitsCountShrank && !isTraditionalPagination) {
      setHasTriggeredMap(new Map());
    }

    if (hasNewQuery) {
      scrollUpIfNecessary({
        prefersReducedMotion: !!window?.matchMedia(`(prefers-reduced-motion: reduce)`)?.matches === true,
        mustBeScrolledPastThisElementId: 'search-results-header-wrapper',
      });
    }

    if (typeof window !== 'undefined' && 'IntersectionObserver' in window) {
      setWindowIsSafe(true);
    }

    if (infiniteScrollObserver && infiniteScrollTriggerRef.current) {
      infiniteScrollObserver.observe(infiniteScrollTriggerRef.current);
    }

    return () => {
      if (infiniteScrollObserver) infiniteScrollObserver.disconnect();
    };
  }, [
    infiniteScrollTriggerRef,
    totalHitsDisplayed,
    previousHitsCount,
    isTraditionalPagination,
    infiniteScrollObserver,
    previousQuery,
    eventingData?.searchQuery,
  ]);
  // end experimental pagination related

  if ((isLoading && isTraditionalPagination) || !hits) {
    return <ProductsCardsCdsPlaceholder resultsPerPage={resultsPerPage} variant="grid" />;
  }

  const mappedPremiumProducts: Record<string, PremiumProductWithMetadata> = {};

  premiumProductList.forEach((degree) => {
    mappedPremiumProducts[degree.slug] = degree;
  });

  return (
    <>
      <Grid container component="ul" css={[styles.container, !isTraditionalPagination && { marginBottom: '0' }]}>
        {hits.map((hit, hitIndex) => {
          if (!isSearchProductHit(hit)) return null;
          const product = convertHitToDegreeCardProduct(hit, mappedPremiumProducts, atAGlanceData);
          const url = getUrlFromProductVariant(product.productVariant, product.slug ?? '');
          const trackingData = {
            entityId: product.slug,
            link: url,
            ...product,
            productRankBaseZero: hitIndex,
          };
          const clickEventV2 = () =>
            retrackedTrack({
              trackingName: 'top_product_card',
              trackingData,
              action: 'click',
            });
          return (
            <Grid item md={4} sm={6} xs={12} key={product.slug} css={styles.degreeCardRow}>
              <DegreeCard
                key={hit.id}
                product={product}
                entityIndex={hitIndex}
                sectionName="paginated_degree_list"
                onClick={clickEventV2}
                displayVariant={isMobile ? 'list' : 'grid'}
              />
            </Grid>
          );
        })}
      </Grid>
      {isLoading && !isTraditionalPagination && (
        <ProductsCardsCdsPlaceholder resultsPerPage={resultsPerPage} variant="grid" />
      )}
    </>
  );
};

// Only call contentful if the slug is defined and the data is not already in the global object
export const filterPremiumHits = ({ hits }: { hits?: SearchHit[] }) => {
  const premiumProducts =
    hits?.filter(
      (hit) =>
        hit?.__typename === 'Search_ProductHit' &&
        hit?.productType &&
        isPremiumProduct(hit?.productType as (typeof LEARNING_PRODUCTS)[keyof typeof LEARNING_PRODUCTS])
    ) || [];

  const hitsWithSlug = premiumProducts
    .map((hit) => ({ slug: isSearchProductHit(hit) ? getHitSlug(hit.url || undefined) : undefined }))
    .filter((hit) => !!hit.slug && !(hit.slug in PREMIUM_PRODUCT_TO_DURATION));

  return { hitsWithSlug };
};

export default memo(SearchCards);
