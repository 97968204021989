/* @jsx jsx */
import { css, jsx } from '@emotion/react';

import type { MutableRefObject } from 'react';
import * as React from 'react';

import { processImage } from 'js/lib/imgix';

import { ProductCard, Typography2 } from '@coursera/cds-core';
import type { ProductType, SectionName } from '@coursera/event-pulse-types';
import { useTracker, useVisibilityTracker } from '@coursera/event-pulse/react';

import type { ProgramAdmissionDeadlines } from 'bundles/degree-description/types';
import {
  getDeadlineCopyForDegreeCard,
  getDeadlineCopyForHubPage,
} from 'bundles/degree-description/utils/ctaModuleDeadline';
import { TrackedLink2 } from 'bundles/page/components/TrackedLink2';
import { PRODUCT_VARIANT_TO_PRODUCT_NAME } from 'bundles/premium-hub/constants';
import { useModuleSectionEventTrackingData } from 'bundles/premium-hub/hooks/useModuleSectionEventTrackingData';
import type { ProductCardProps } from 'bundles/premium-hub/types';
import { getUrlFromProductVariant } from 'bundles/premium-hub/utils';
import {
  getEventingProductTypeFromVariant,
  removeProductIdPrefix,
} from 'bundles/premium-hub/utils/dataTransformationUtils';

import _t from 'i18n!nls/premium-hub';

type Props = {
  product: ProductCardProps;
  entityIndex: number;
  sectionName: SectionName;
  onClick?: () => void;
  displayVariant?: 'grid' | 'list';
  subindex?: number;
};

type PropsToWrappedCard = Props & {
  customLinkProps?: {
    trackingName: string;
    withVisibilityTracking: boolean;
    requireFullyVisible: boolean;
    data: Record<string, unknown>;
    refAlt?: React.RefObject<HTMLAnchorElement>;
  };
};

const styles = {
  productCardContainer: css`
    display: flex;
    margin-bottom: var(--cds-spacing-400);
  `,
  partnerCard: css`
    padding: 0 var(--cds-spacing-200) var(--cds-spacing-200) 0;
    height: 100%;

    .cds-ProductCard-gridPreviewContainer {
      padding: var(--cds-spacing-200);
      background-color: 'var(--cds-color-neutral-primary-invert)';
      border-radius: 8px;
    }

    .cds-ProductCard-partnerLogos {
      display: none !important;
    }

    .cds-CommonCard-previewImage {
      margin: 0 !important;
      max-height: 124px;
      width: 100%;

      img {
        object-fit: contain !important;
      }
    }
  `,
  partnerName: css`
    color: var(--cds-color-neutral-primary-weak);
    margin-bottom: var(--cds-spacing-100);
  `,
  metadata: css`
    color: var(--cds-color-neutral-primary-weak);
  `,
  degreeComparisonCheckbox: css`
    margin-top: var(--cds-spacing-200);
  `,
};

const WrappedDegreeCard = ({
  product,
  entityIndex,
  sectionName,
  onClick,
  displayVariant = 'grid',
  subindex,
  customLinkProps,
}: PropsToWrappedCard) => {
  const trackV3 = useTracker();
  const { index: sectionIndex } = useModuleSectionEventTrackingData();
  const { name, productVariant, slug, partner, description, programDeadlines } = product;

  const { partnerMonotoneLogo, squareLogo } = partner || {};
  const deadlineCopy = getDeadlineCopyForHubPage(
    (programDeadlines ?? []) as ProgramAdmissionDeadlines,
    getDeadlineCopyForDegreeCard
  ).body;

  const productCardTrackingDataV3 = {
    productCard: {
      index: entityIndex,
    },
    product: {
      id: removeProductIdPrefix(product.id ?? ''),
      slug: product.slug ?? '',
      name: product.name,
      type: getEventingProductTypeFromVariant(product.productVariant) as ProductType,
    },
    pageSection: { sectionName, index: sectionIndex, ...(subindex !== undefined && { subindex }) },
  };

  const degreeCardRef: MutableRefObject<HTMLDivElement | null> = useVisibilityTracker(
    'view_product_card',
    productCardTrackingDataV3,
    [],
    { fullyVisible: true }
  );

  if (!name || !slug) {
    return null;
  }

  const url = getUrlFromProductVariant(productVariant, slug);
  const productName = PRODUCT_VARIANT_TO_PRODUCT_NAME[productVariant];
  const partnerLogoUsed = processImage(displayVariant === 'grid' ? partnerMonotoneLogo : squareLogo);

  const trackClick = () => {
    if (onClick) onClick();
    trackV3('click_product_card', productCardTrackingDataV3);
  };

  return (
    <div css={styles.partnerCard} data-testid="top-degree-card" data-test="TopProductCard">
      <ProductCard
        variant={displayVariant}
        productType="Degree"
        aria-label={_t('Go to degree, #{productName}. #{description}. #{deadlineCopy}', {
          productName: name,
          description,
          deadlineCopy,
        })}
        key={slug}
        partners={[
          {
            name: partner?.name ?? _t('Coursera Project Network'),
          },
        ]}
        renderPreviewImage={() => (
          <img
            src={partnerLogoUsed}
            aria-hidden="true"
            loading="lazy"
            alt={_t('#{partnerName} logo', { partnerName: partner?.name })}
          />
        )}
        ref={degreeCardRef}
        title={{
          name: productName ? _t('#{name} #{productName}', { name, productName }) : name,
          linkProps: {
            href: url,
            target: '_blank',
            rel: 'noopener noreferrer',
            onClick: trackClick,
            // we use TrackedLink2 for visibility tracking because wrapping the card in
            // TrackedDiv with visibility trackign adds an extra "blank" div, which causes
            // flexboxes and thus grid containers/items to display incorrectly
            component: TrackedLink2,
          },
          ...(customLinkProps && { customLinkProps }),
        }}
        body={
          description && (
            <Typography2 component="span" variant="bodySecondary" css={styles.metadata}>
              {description}
            </Typography2>
          )
        }
        footer={
          <div>
            {deadlineCopy && (
              <Typography2 component="span" variant="bodySecondary" css={styles.metadata}>
                {deadlineCopy}
              </Typography2>
            )}
          </div>
        }
      />
    </div>
  );
};

const DegreeCard = (props: Props) => {
  return (
    <WrappedDegreeCard
      {...props}
      customLinkProps={{
        trackingName: 'degree_card',
        data: {
          degreeSlug: props.product.slug,
        },
        withVisibilityTracking: true,
        requireFullyVisible: false,
      }}
    />
  );
};

export default DegreeCard;
