// TODO: we need to clean up this file and usages when rollout:
// 1. remove the education attainment
// 2. remove setHasUserSubmitted
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useRetracked } from 'js/lib/retracked';

import localStorageEx from 'bundles/common/utils/localStorageEx';
import {
  DEGREE_HUB_APPLICATION_OPEN_OPTIONS,
  DEGREE_HUB_PRODUCT_VARIANT_FILTERS,
  HubPages,
  ProductVariant,
} from 'bundles/premium-hub/constants';
import type { PremiumHubPageType } from 'bundles/premium-hub/constants';
import type { Domain, PremiumProductWithMetadata } from 'bundles/premium-hub/types/index';

type StoredState = {
  educationLevel?: number;
  productVariant?: string;
  domain?: string;
  applicationOpen?: string;
  epochTimestamp: number;
};

type InputProps = {
  degreeListWithMetadata?: Array<PremiumProductWithMetadata>;
  pathname?: string;
  domains?: Domain[];
  currentPageType?: PremiumHubPageType;
};

type StateForChildren = {
  filteredDegreeList?: Array<PremiumProductWithMetadata>;
};

type StateValues = {
  hasUserSubmitted: boolean;
  selectedEducationAttainment?: number;
  selectedProductVariant?: string;
  selectedDomain?: string;
  selectedApplicationOpen?: string;
  submittedEducationAttainment?: number;
  submittedProductVariant?: string;
  submittedDomain?: string;
  submittedApplicationOpen?: string;
  availableProductVariantFilters?: string[];
  availableDomainFilters?: Domain[];
  availableApplicationOpenFilters?: { id: string; name: string }[];
  selectedProductVariantValue?: string[];
  selectedDomainValue?: string[];
  selectedApplicationOpenValue?: string;
  open?: boolean;
};

type StateSetters = {
  setHasUserSubmitted: (hasUserSubmitted: boolean) => void;
  setSelectedEducationAttainment: (educationAttainment: number | undefined) => void;
  setSelectedProductVariant: (productVariant: string | undefined) => void;
  setSelectedDomain: (domain: string | undefined) => void;
  setSelectedApplicationOpen: (applicationOpen: string | undefined) => void;
  setSubmittedEducationAttainment: (educationAttainment: number | undefined) => void;
  setSubmittedProductVariant: (productVariant: string | undefined) => void;
  setSubmittedDomain: (domain: string | undefined) => void;
  setSubmittedApplicationOpen: (applicationOpen: string | undefined) => void;
  clearAll?: () => void;
  setOpen?: (open: boolean) => void;
};

export type DegreeFilterState = StateValues & StateSetters;

type State = StateForChildren & StateValues;

export type PropsFromDegreeFilterStateManager = InputProps &
  StateForChildren & { degreeFilterState: DegreeFilterState };

const filterFunctionFactory =
  (productVariant?: string, domain?: string, applicationOpen?: string) =>
  (degree: PremiumProductWithMetadata): boolean => {
    if (!productVariant && !domain && !applicationOpen) {
      return true;
    }

    const productVariantArray = productVariant ? productVariant.split(',') : [];
    const domainArray = domain ? domain.split(',') : [];

    const isNextAdmissionSubtermDeadlineDateValid = Boolean(
      degree?.upcomingTermDates?.nextAdmissionSubtermDeadlineDate?.seconds
    );

    const productVariantFilter = productVariantArray.length
      ? productVariantArray.includes(degree.productVariant ?? '')
      : true;
    const domainFilter = domainArray.length
      ? !!domainArray.filter((domainItem) => degree.domainIds.includes(domainItem)).length
      : true;
    const applicationOpenFilter =
      applicationOpen === 'onPlatform'
        ? isNextAdmissionSubtermDeadlineDateValid
        : applicationOpen === 'offPlatform'
        ? !isNextAdmissionSubtermDeadlineDateValid
        : true;

    return productVariantFilter && domainFilter && applicationOpenFilter;
  };

const LOCALSTORAGE_PREFIX_SUBMITTED = 'storedDegreeFilterState_submitted_multiselect_';
const LOAD_PREV_STATE_TIMEOUT_MINS = 3;

export const convertPathnameToSlug = (pathname: string) =>
  pathname
    .split('/')
    .filter((part) => part.length > 0)
    .join('-');

export const getLocalStorageKey = (pathname: string) =>
  `${LOCALSTORAGE_PREFIX_SUBMITTED}${convertPathnameToSlug(pathname)}`;

export const setDegreeFilterLocalStorage = (
  pathname: string,
  productVariant: string | undefined,
  domain: string | undefined,
  applicationOpen: string | undefined
) => {
  const temp: StoredState = {
    productVariant,
    domain,
    applicationOpen,
    epochTimestamp: new Date().getTime(),
  };
  localStorageEx.setItem<StoredState>(getLocalStorageKey(pathname), temp, JSON.stringify);
};

/**
 *
 * @param pathname The pathname of the current route. Ex: "/degrees/computer-science/"
 * @param getLastSubmitted If this is true, then the function will only return the most recent __submitted__ filter entry and not the most recent selected entry from the dropdowns.
 * @returns StoredState = {
 *   educationLevel?: number | undefined;
 *   productVariant?: string | undefined;
 *   domain?: string | undefined;
 *   epochTimestamp: number;
 * }
 */
export const getDegreeFilterLocalStorage = (pathname: string): StoredState | null => {
  const prevState: StoredState | null = localStorageEx.getItem(getLocalStorageKey(pathname), JSON.parse, null);
  if (!prevState) return null;
  const { epochTimestamp } = prevState;
  const now = new Date().getTime();
  const differenceMins = (now - epochTimestamp) / 60000;
  if (differenceMins >= LOAD_PREV_STATE_TIMEOUT_MINS) return null;
  return prevState;
};

export const useMultiSelectDegreeFilterStateManager = ({
  degreeListWithMetadata,
  pathname,
  domains = [],
  currentPageType,
}: InputProps): PropsFromDegreeFilterStateManager => {
  const [filteredDegreeList, setFilteredDegreeList] = useState<State['filteredDegreeList']>(degreeListWithMetadata);
  const [hasUserSubmitted, setHasUserSubmitted] = useState<State['hasUserSubmitted']>(true);
  const trackComponent = useRetracked();

  const [selectedEducationAttainment, setSelectedEducationAttainment] =
    useState<State['selectedEducationAttainment']>();
  const [selectedProductVariant, setSelectedProductVariant] = useState<State['selectedProductVariant']>('');
  const [selectedDomain, setSelectedDomain] = useState<State['selectedApplicationOpen']>('');
  const [selectedApplicationOpen, setSelectedApplicationOpen] = useState<State['selectedApplicationOpen']>('');

  const [submittedEducationAttainment, setSubmittedEducationAttainment] =
    useState<State['submittedEducationAttainment']>();
  const [submittedProductVariant, setSubmittedProductVariant] = useState<State['submittedProductVariant']>('');
  const [submittedDomain, setSubmittedDomain] = useState<State['submittedDomain']>('');
  const [submittedApplicationOpen, setSubmittedApplicationOpen] = useState<State['submittedApplicationOpen']>('');

  // Only show the postgraduate filter on DCAT/Geo pages
  const productVariantFilters = [
    ...DEGREE_HUB_PRODUCT_VARIANT_FILTERS,
    ...(currentPageType === HubPages.DegreesCategoryHubPage ? [ProductVariant.PostgraduateDiploma] : []),
  ];

  const getFilteredDegreeList = useCallback<(...args: $TSFixMe[]) => $TSFixMe>(() => {
    return (
      degreeListWithMetadata?.filter(
        filterFunctionFactory(submittedProductVariant, submittedDomain, submittedApplicationOpen)
      ) || []
    );
  }, [degreeListWithMetadata, submittedDomain, submittedProductVariant, submittedApplicationOpen]);

  const [open, setOpen] = useState<State['open']>(false);
  const [startupComplete, setStartupComplete] = useState<boolean>(false);

  useEffect(() => {
    setSelectedDomain(submittedDomain);
    setSelectedProductVariant(submittedProductVariant);
    setSelectedApplicationOpen(submittedApplicationOpen);
  }, [submittedDomain, submittedProductVariant, submittedApplicationOpen]);

  useEffect(() => {
    if (!degreeListWithMetadata?.length && startupComplete) return;
    if (!pathname) return;
    const prevState: StoredState | null = getDegreeFilterLocalStorage(pathname);
    if (prevState) {
      const { domain, productVariant, applicationOpen } = prevState;
      if (domain) {
        setSelectedDomain(domain);
        setSubmittedDomain(domain);
      }
      if (productVariant) {
        setSelectedProductVariant(productVariant);
        setSubmittedProductVariant(productVariant);
      }
      if (applicationOpen) {
        setSelectedApplicationOpen(applicationOpen);
        setSubmittedApplicationOpen(applicationOpen);
      }
    }
    setFilteredDegreeList(getFilteredDegreeList());
    setStartupComplete(true);
    /**
     * We only want to run this useEffect block on startup, which is the only time that degreeListWithMetadata would change
     * (from undefined to a populated array after queries finish)
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [degreeListWithMetadata]);

  useEffect(() => {
    if (!startupComplete || !filteredDegreeList || filteredDegreeList.length < 1) return;
    setDegreeFilterLocalStorage(pathname ?? '', submittedProductVariant, submittedDomain, submittedApplicationOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, submittedDomain, submittedProductVariant, submittedApplicationOpen]);

  useEffect(() => {
    setFilteredDegreeList(getFilteredDegreeList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedProductVariant, submittedDomain, submittedApplicationOpen]);

  const selectedProductVariantValue = useMemo(
    () => (selectedProductVariant ? selectedProductVariant.split(',') : []),
    [selectedProductVariant]
  );
  const selectedDomainValue = useMemo(() => (selectedDomain ? selectedDomain.split(',') : []), [selectedDomain]);
  const selectedApplicationOpenValue = selectedApplicationOpen;

  const clearAll = () => {
    trackComponent({
      trackingData: {},
      trackingName: 'multiselect_filters_reset',
      action: 'click',
    });
    setSelectedDomain('');
    setSelectedProductVariant('');
    setSelectedApplicationOpen('');
    setSubmittedDomain('');
    setSubmittedProductVariant('');
    setSubmittedApplicationOpen('');
  };

  const availableProductVariantFilters = useMemo(
    () => {
      let optionsResult = [];
      if (selectedDomainValue.length > 0) {
        const productVariantOptions =
          degreeListWithMetadata?.reduce((options, degree) => {
            if (
              degree.productVariant &&
              selectedDomainValue.filter((domain) => degree.domainIds?.includes(domain)).length
            ) {
              options.add(degree.productVariant);
            }
            return options;
          }, new Set<string>()) ?? new Set();
        optionsResult = Array.from(productVariantOptions);
      } else {
        optionsResult = productVariantFilters;
      }
      return optionsResult.sort();
    }, // FIXME: existing react-hooks/exhaustive-deps violations are excused to prevent seeing errors when modifying other parts of the same file; please fix it carefully
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [degreeListWithMetadata, selectedDomainValue]
  );

  const availableDomainFilters = useMemo(() => {
    if (selectedProductVariantValue.length > 0) {
      const domainOptions =
        degreeListWithMetadata?.reduce((options, degree) => {
          if (degree.domainIds?.length && selectedProductVariantValue.includes(degree.productVariant ?? '')) {
            for (const id of degree.domainIds) {
              options.add(id);
            }
          }
          return options;
        }, new Set<string>()) ?? new Set();
      return domains.filter((domain) => domainOptions.has(domain.id));
    }

    return domains;
  }, [degreeListWithMetadata, domains, selectedProductVariantValue]);

  const availableApplicationOpenFilters = DEGREE_HUB_APPLICATION_OPEN_OPTIONS;

  useEffect(() => {
    const filteredProductVariants = selectedProductVariantValue.filter((productVariant) =>
      availableProductVariantFilters.includes(productVariant)
    );
    const filteredProductVariantStr = filteredProductVariants.length === 0 ? '' : filteredProductVariants.join(',');

    const filteredDomains = selectedDomainValue.filter(
      (domainId) => availableDomainFilters.filter((domain) => domain.id === domainId).length
    );
    const filteredDomainStr = filteredDomains.length === 0 ? '' : filteredDomains.join(',');

    // web/mobile will take differenct actions
    if (open) {
      setSelectedProductVariant(filteredProductVariantStr);
      setSelectedDomain(filteredDomainStr);
    } else {
      setSubmittedProductVariant(filteredProductVariantStr);
      setSubmittedDomain(filteredDomainStr);
    }
  }, [availableProductVariantFilters, availableDomainFilters, selectedProductVariantValue, selectedDomainValue, open]);

  return {
    filteredDegreeList,
    degreeFilterState: {
      hasUserSubmitted,
      setHasUserSubmitted,
      selectedEducationAttainment,
      setSelectedEducationAttainment,
      selectedProductVariant,
      setSelectedProductVariant,
      selectedDomain,
      setSelectedDomain,
      selectedApplicationOpen,
      setSelectedApplicationOpen,
      submittedEducationAttainment,
      setSubmittedEducationAttainment,
      submittedProductVariant,
      setSubmittedProductVariant,
      submittedDomain,
      setSubmittedDomain,
      submittedApplicationOpen,
      setSubmittedApplicationOpen,
      availableProductVariantFilters,
      availableDomainFilters,
      availableApplicationOpenFilters,
      selectedProductVariantValue,
      selectedDomainValue,
      selectedApplicationOpenValue,
      clearAll,
      open,
      setOpen,
    },
  };
};

export default useMultiSelectDegreeFilterStateManager;
