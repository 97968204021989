import * as React from 'react';
import type { InjectedRouter } from 'react-router';

import { compose } from 'recompose';

import Retracked from 'js/app/retracked';
import connectToStores from 'js/lib/connectToStores';
import { useLocation } from 'js/lib/useRouter';

import PageFooter from 'bundles/page-footer/components/PageFooter';
import PremiumHubPageHeader from 'bundles/premium-hub/components/shared/PremiumHubPageHeader';
import ApplicationStoreClass from 'bundles/ssr/stores/ApplicationStore';

import 'css!../styl/index';

type Context = {
  router: InjectedRouter;
  getStore: (storeName: string) => void;
};

type Props = {
  children?: React.ReactNode;
  requestCountryCode?: string;
  csrfToken?: string;
} & Context;

const PremiumHubApp: React.FunctionComponent<Props> = ({ children }) => {
  const location = useLocation();
  const onDegreesPage = location.pathname.includes('degrees');
  return (
    <div className="rc-PremiumHubApp">
      {!onDegreesPage && <PremiumHubPageHeader isSimplified={false} />}
      {children}
      <PageFooter />
    </div>
  );
};

export default compose<Props, {}>(
  Retracked.createTrackedContainer(() => ({
    namespace: {
      app: 'program_hub',
      page: 'premium_hub_app',
    },
  })),
  connectToStores<Props, Props & { csrfToken: string; requestCountryCode: string }>(
    [ApplicationStoreClass],
    (ApplicationStore, props) => ({
      ...props,
      csrfToken: ApplicationStore.getState().csrfToken,
      requestCountryCode: ApplicationStore.getState().requestCountryCode,
    })
  )
)(PremiumHubApp);
